@mixin fontStyle($fontSize: 12px, $fontWeight: normal, $color: #898989) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color;
}

.password {
  margin-top: 20px;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  span {
    @include fontStyle($fontWeight: bold);
    text-transform: capitalize;
    margin: 0 0 8px 8px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .error {
    margin: 0px 0 20px;
    color: tomato;
    font-size: 12px;
    text-transform: none;
  }
}

.marginTextCreate {
  margin-top: 20px;
}

.textNormal {
  span {
    @include fontStyle();
  }
}

.textNormalMargin {
  margin-top: 20px;
  span {
    @include fontStyle();
  }
}
