.input {
  width: 100%;
  height: 45px;
  background-color: #ffffff;
  border: 2px solid #898989;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  padding-left: 8px;
  &::placeholder {
    color: #898989;
    text-transform: capitalize;
  }

  &:-ms-input-placeholder {
    color: #898989;
  }

  &::-ms-input-placeholder {
    color: #898989;
  }
  &:focus {
    border: 3px solid #000000;
  }
  &:hover {
    cursor: pointer;
  }
}

.redInput {
  border-color: tomato;
}

.chooseYear {
  width: 63px;
  height: 38px;
  padding: 9px 9px;
  border-radius: 5px;
  background-color: #f2f1f1;
  border: 3px solid transparent;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  opacity: 0.8;
  &::placeholder {
    color: #000000;
    font-weight: 500;
    text-transform: capitalize;
  }

  &:-ms-input-placeholder {
    color: #000000;
  }

  &::-ms-input-placeholder {
    color: #000000;
  }
  &:focus {
    border: 3px solid rgba(45, 45, 45, 0.75);
  }
  &:hover {
    cursor: pointer;
  }
}

.choosed {
  width: 63px;
  height: 38px;
  padding: 9px 9px;
  border-radius: 5px;
  background-color: #f2f1f1;
  font-size: 16px;
  font-weight: 500;
  color: #00897b;
  border: 3px solid #00897b;
  margin-right: 11px;
  white-space: nowrap;
  cursor: pointer;
}

.cardNumber {
  height: 35px;
  padding: 9px 9px;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  border: 2px solid rgba(210, 213, 219, 0.8);
  cursor: pointer;
}

.editOrder {
  height: auto;
  width: 80%;
  font-size: 16px;
  color: #2d2d2d;
  font-weight: normal;
  text-transform: capitalize;
  border: none;
  padding: 0;
  outline: none;
  &:focus {
    border: none;
  }
}
