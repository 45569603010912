.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    font-size: 30px;
    letter-spacing: 2px;
    color: #2d2d2d;
  }
  .btnBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 450px) {
      padding: 0 20px;
    }
    .textConfirm {
      width: 80%;
      font-size: 12px;
      font-weight: bold;
      color: #ffffff;
      background-color: #00897b;
      padding: 10px 0;
      margin: 30px 0;
      text-align: center;
      text-transform: uppercase;
    }
  }
}
