.formContainer {
  width: 330px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;

  @media only screen and (max-width: 450px) {
    width: 100%;
    padding: 0 20px;
    margin-top: 30px;
    margin-bottom: 100px;
  }
  .btnSubmit {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0 20px;
  }
  .labelCheckButton {
    width: 100%;
    text-align: start;
    font-size: 12px;
    color: #898989;
    margin-bottom: 10px;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  .statusResetPass,
  .error {
    width: 75%;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    padding: 10px 0;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  .statusResetPass {
    background-color: #00897b;
  }
  .error {
    background-color: #f12c32;
  }
}
