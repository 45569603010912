.container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 376px;
  .message {
    border-color: red;
    color: black;
    margin: 20px 0;
    padding: 10px;
  }
  .error {
    background-color: red;
    color: white;
    padding: 10px 20px;
  }
  h3 {
    font-size: 30px;
    letter-spacing: 2px;
    color: #2d2d2d;
  }
  .form {
    width: 330px;
    margin: 67px 0;
    input {
      margin-bottom: 20px;
    }
    button {
      margin-left: 11px;
    }
    @media only screen and (max-width: 450px) {
      width: 100%;
      padding: 0 20px;
    }
  }
}
