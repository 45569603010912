@mixin fontStyle( $fontSize: 12px, $fontWeight:normal, $color:#898989,) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color;
}

.labelBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span{
    @include fontStyle($fontWeight: bold);
    text-transform: capitalize;
    margin: 0 0 8px 8px;
  }
}