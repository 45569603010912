.createAccount {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .label {
    font-size: 18px;
    line-height: 1.67;
    color: rgba(45, 45, 45, 0.75);
    &::first-letter {
      text-transform: capitalize;
    }
  }
}